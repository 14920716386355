var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"noorplay_footer",attrs:{"dir":_vm.localDisplayLang === 'ara' ? 'rtl' : 'ltr'}},[_c('div',{staticClass:"container footer"},[_c('div',{staticClass:"logo_container"},[_c('div',{staticClass:"flex_container"},[_c('div',{staticClass:"download_our_app"},[_c('p',{staticClass:"download_text"},[_vm._v(_vm._s(_vm.$t("Download now")))]),_c('ul',{staticClass:"app_links_container"},[_c('li',{staticClass:"app-links"},[_c('a',{attrs:{"href":_vm.appConfig.playstore.android,"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/google-play.png"),"alt":""}})])]),_c('li',{staticClass:"app-links"},[_c('a',{attrs:{"href":_vm.appConfig.playstore.apple,"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/appstore.png"),"alt":""}})])]),(_vm.appConfig.playstore.androidtv)?_c('li',{staticClass:"app-links"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"bordered-div"},[_c('a',{attrs:{"href":_vm.appConfig.playstore.androidtv,"target":"_blank"}},[_c('img',{staticClass:"contained",attrs:{"src":require("@/assets/icons/android-tv.svg"),"alt":""}})])])])]):_vm._e(),_c('li',{staticClass:"app-links"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"bordered-div"},[_c('a',{attrs:{"href":_vm.appConfig.playstore.firetv,"target":"_blank"}},[_c('img',{staticClass:"contained",attrs:{"src":require("@/assets/icons/fire-tv.svg"),"alt":""}})])])])])])]),_c('div',{staticClass:"social_icons"},[_c('div',{staticClass:"icon-paper"},[_vm._v(_vm._s(_vm.$t("Connect with us")))]),_c('ul',{staticClass:"icon_list"},[(_vm.appConfig.providerDetails.social.facebook)?_c('li',{staticClass:"icon",on:{"mouseover":function($event){return _vm.actSoicalLogin('fb')}}},[_c('a',{attrs:{"href":_vm.isActivefacebookLink
                    ? _vm.appConfig.providerDetails.social.facebook
                    : '',"rel":"noopener","aria-label":"facebook link","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/facebookicon.svg"),"alt":"facebook_img"}})])]):_vm._e(),(_vm.appConfig.providerDetails.social.instagram)?_c('li',{staticClass:"icon",on:{"mouseover":function($event){return _vm.actSoicalLogin('insta')}}},[_c('a',{attrs:{"href":_vm.isActiveInstagramLink
                    ? _vm.appConfig.providerDetails.social.instagram
                    : '',"rel":"noopener","aria-label":"instagram link","target":"_blank"}},[_c('img',{staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("@/assets/icons/instagramicon.svg"),"alt":"instagram_img"}})])]):_vm._e(),(_vm.appConfig.providerDetails.social.twitter)?_c('li',{staticClass:"icon",on:{"mouseover":function($event){return _vm.actSoicalLogin('twitter')}}},[_c('a',{attrs:{"href":_vm.isActiveTwitterLink
                    ? _vm.appConfig.providerDetails.social.twitter
                    : '',"rel":"noopener","aria-label":"twitter link","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/Socialuj.svg"),"alt":"twitter_img"}})])]):_vm._e(),(_vm.appConfig.providerDetails.social.youtube)?_c('li',{staticClass:"icon",on:{"mouseover":function($event){return _vm.actSoicalLogin('tube')}}},[_c('a',{attrs:{"href":_vm.isActiveYoutubeLink
                    ? _vm.appConfig.providerDetails.social.youtube
                    : '',"rel":"noopener","aria-label":"pinterest link","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/Youtubeicon.svg"),"alt":"youtube"}})])]):_vm._e()])])])]),_c('div',{staticClass:"footerMenu"},[_c('ul',[_c('li',{staticClass:"noSelect"},[_c('router-link',{staticClass:"noSelect",attrs:{"to":_vm.showAbout()}},[_vm._v(_vm._s(_vm.$t("About Us")))])],1),_c('li',[_c('router-link',{staticClass:"noSelect",attrs:{"to":_vm.showTermsOfUse()}},[_vm._v(" "+_vm._s(_vm.$t("terms of use")))])],1),_c('li',[_c('router-link',{staticClass:"noSelect",attrs:{"to":_vm.showPrivacyPolicy()}},[_vm._v(" "+_vm._s(_vm.$t("privacy policy")))])],1),_c('li',[_c('router-link',{staticClass:"noSelect globalDiv",attrs:{"to":_vm.showFaq()}},[_vm._v(" "+_vm._s(_vm.$t("Help Center"))+" ")])],1)])]),_c('div',{staticClass:"copyRightTxt"},[_c('p',[_vm._v(" "+_vm._s(_vm.cpyRhTxt)+" ")])])]),_c('transition',{attrs:{"name":"popup-animate"}},[_c('div',[(_vm.showAboutPopup)?_c('About',{attrs:{"closePopup":function () { return _vm.toggleAboutPopup(false); }}}):_vm._e(),(_vm.showFaqPopup)?_c('Faq',{attrs:{"closePopup":function () { return _vm.toggleFaqPopup(false); }}}):_vm._e(),(_vm.showContactPopup)?_c('Contact',{attrs:{"closePopup":function () { return _vm.hideContactPopup(false); }}}):_vm._e(),(_vm.enableTicket)?_c('createTicket',{attrs:{"closePopup":function () { return _vm.toggleCreateTicket(false); }}}):_vm._e(),(_vm.showAppShare)?_c('AppReferal',{attrs:{"closePopup":function () { return _vm.toggleAppReferal(false); }}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }