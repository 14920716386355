<template>
  <div
    class="noorplay_footer"
    :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
  >
    <div class="container footer">
      <div class="logo_container">
        <div class="flex_container">
          <div class="download_our_app">
            <p class="download_text">{{ $t("Download now") }}</p>
            <ul class="app_links_container">
              <li class="app-links">
                <a :href="appConfig.playstore.android" target="_blank">
                  <img src="@/assets/icons/google-play.png" alt />
                </a>
              </li>
              <li class="app-links">
                <a :href="appConfig.playstore.apple" target="_blank">
                  <img src="@/assets/icons/appstore.png" alt />
                </a>
              </li>
              <li class="app-links" v-if="appConfig.playstore.androidtv">
                <div class="banner">
                  <div class="bordered-div">
                      <a :href="appConfig.playstore.androidtv"  target="_blank"> 
                      <img
                        class="contained"
                        src="@/assets/icons/android-tv.svg"
                        alt
                      />
                    </a>
                  </div>
                </div>
              </li>

              <li class="app-links">
                <div class="banner">
                  <div class="bordered-div">
                    <a :href="appConfig.playstore.firetv" target="_blank">
                      <img
                        class="contained"
                        src="@/assets/icons/fire-tv.svg"
                        alt
                      />
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="social_icons">
            <div class="icon-paper">{{ $t("Connect with us") }}</div>
            <ul class="icon_list">
              <li
                class="icon"
                v-if="appConfig.providerDetails.social.facebook"
                @mouseover="actSoicalLogin('fb')"
              >
                <a
                  :href="
                    isActivefacebookLink
                      ? appConfig.providerDetails.social.facebook
                      : ''
                  "
                  rel="noopener"
                  aria-label="facebook link"
                  target="_blank"
                >
                  <img
                    src="@/assets/icons/facebookicon.svg"
                    alt="facebook_img"
                  />
                </a>
              </li>
              <li
                class="icon"
                v-if="appConfig.providerDetails.social.instagram"
                @mouseover="actSoicalLogin('insta')"
              >
                <a
                  :href="
                    isActiveInstagramLink
                      ? appConfig.providerDetails.social.instagram
                      : ''
                  "
                  rel="noopener"
                  aria-label="instagram link"
                  target="_blank"
                >
                  <img
                    src="@/assets/icons/instagramicon.svg"
                    alt="instagram_img"
                    style="width: 20px; height: 20px"
                  />
                </a>
              </li>
              <li
                class="icon"
                v-if="appConfig.providerDetails.social.twitter"
                @mouseover="actSoicalLogin('twitter')"
              >
                <a
                  :href="
                    isActiveTwitterLink
                      ? appConfig.providerDetails.social.twitter
                      : ''
                  "
                  rel="noopener"
                  aria-label="twitter link"
                  target="_blank"
                >
                  <img src="@/assets/icons/Socialuj.svg" alt="twitter_img" />
                </a>
              </li>

              <li
                class="icon"
                v-if="appConfig.providerDetails.social.youtube"
                @mouseover="actSoicalLogin('tube')"
              >
                <a
                  :href="
                    isActiveYoutubeLink
                      ? appConfig.providerDetails.social.youtube
                      : ''
                  "
                  rel="noopener"
                  aria-label="pinterest link"
                  target="_blank"
                >
                  <img src="@/assets/icons/Youtubeicon.svg" alt="youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footerMenu">
        <ul>
          <li class="noSelect">
            <router-link :to="showAbout()" class="noSelect">{{
              $t("About Us")
            }}</router-link>
          </li>
          <li>
            <router-link class="noSelect" :to="showTermsOfUse()">
              {{ $t("terms of use") }}</router-link
            >
          </li>

          <li>
            <router-link class="noSelect" :to="showPrivacyPolicy()">
              {{ $t("privacy policy") }}</router-link
            >
          </li>

          <li>
            <router-link class="noSelect globalDiv" :to="showFaq()">
              {{ $t("Help Center") }}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="copyRightTxt">
        <p>
          {{ cpyRhTxt }}
        </p>
      </div>
    </div>
    <transition name="popup-animate">
      <div>
        <About
          v-if="showAboutPopup"
          :closePopup="() => toggleAboutPopup(false)"
        ></About>
        <Faq
          v-if="showFaqPopup"
          :closePopup="() => toggleFaqPopup(false)"
        ></Faq>
        <Contact
          v-if="showContactPopup"
          :closePopup="() => hideContactPopup(false)"
        ></Contact>
        <createTicket
          v-if="enableTicket"
          :closePopup="() => toggleCreateTicket(false)"
        ></createTicket>
        <AppReferal
          v-if="showAppShare"
          :closePopup="() => toggleAppReferal(false)"
        ></AppReferal>
      </div>
    </transition>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapMutations } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
import Utility from "@/mixins/Utility.js";

export default {
  data() {
    return {
      date: new Date(),
      brand: null,
      showAboutPopup: false,
      showFaqPopup: false,
      showContactPopup: false,
      enableTicket: false,
      showAppShare: false,
      net5Logo: require("@/assets/icons/Net5.svg"),
      nammaflixLogo: require("@/assets/icons/nammaflix_logo.png"),
      providerUniqueId: _providerId,
      localDisplayLang: null,
      cpyRhTxt: "",
      isActivePrivacy: false,
      isActiveTerms: false,
      isActivefacebookLink: false,
      isActiveInstagramLink: false,
      isActiveTwitterLink: false,
      isActiveYoutubeLink: false,
      isActiveAboutusLink: false,
      isActiveShowAbout: false,
      isActiveShowFaq: false,
    };
  },
  computed: {
    ...mapGetters(["appConfig", "getRtl"]),
  },
  watch: {
    showDeepHelp(val) {
      if (val) {
        this.showFaqPopup();
      }
    },
  },
  created() {
    this.brand =
      _projectName === "VLIVE"
        ? "vLive"
        : _projectName === "NET5"
        ? "Prime Originals"
        : _projectName === "NAMMAFLIX"
        ? "Anviton"
        : _projectName === "Noor Play"
        ? "Noorplay"
        : "";

    this.branchDeeplinks();

    eventBus.$on("show-contact-support", this.showContact);
    eventBus.$on("create-ticket-popup", this.showCreateTicket);
    eventBus.$on("show-share-app-popup", this.showAppReferral);

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    if (localStorage.getItem("setDisplayLanguageCode")) {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    }
    this.cpyRhTxt = this.$t(window.PROVIDER_SETUP_CONFIG.copyRight_Txt);
    this.isActivePrivacy = window.PROVIDER_SETUP_CONFIG.isPrivacyEnbaled;
    this.isActiveTerms = window.PROVIDER_SETUP_CONFIG.isTermsEnabled;
    this.isActivefacebookLink = window.PROVIDER_SETUP_CONFIG.isFacebookEnabled;
    this.isActiveInstagramLink =
      window.PROVIDER_SETUP_CONFIG.isInstagramEnabled;
    this.isActiveTwitterLink = window.PROVIDER_SETUP_CONFIG.isTwitterEnabled;
    this.isActiveYoutubeLink = window.PROVIDER_SETUP_CONFIG.isYoutubeEnabled;
    this.isActiveAboutusLink = window.PROVIDER_SETUP_CONFIG.isAboutusEnabled;
    this.isActiveShowAbout = window.PROVIDER_SETUP_CONFIG.isShowAboutEnabled;
    this.isActiveShowFaq = window.PROVIDER_SETUP_CONFIG.isShowFaqEnabled;
  },
  methods: {
    ...mapMutations(["setRtl"]),
    actSoicalLogin(social) {
      let colorCode = "";
      if (social == "fb") {
        colorCode = "#1877F2";
      } else if (social == "insta") {
        colorCode = "#D748C0";
      } else if (social == "twitter") {
        colorCode = "#1E90FF";
      } else if (social == "tube") {
        colorCode = "#ED3833";
      }
      document.documentElement.style.setProperty(
        "--social-login-hover-color",
        colorCode
      );
    },
    showAbout() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (!this.isActiveShowAbout) {
        return;
      }
      return { name: "about-switch", params: { lang: currentLanguage } };
      // this.$router.push({name: 'about-switch', params: {lang: currentLanguage}});
    },
    showFaq() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (!this.isActiveShowFaq) {
        return;
      }
      return { name: "faq-switch", params: { lang: currentLanguage } };
    },
    showTermsOfUse() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (!this.isActiveTerms) {
        return;
      }

      return { name: "termsofuse", params: { lang: currentLanguage } };
    },
    showPrivacyPolicy() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (!this.isActivePrivacy) {
        return;
      }

      return { name: "privacypolicy", params: { lang: currentLanguage } };
    },
    showContact() {
      this.showFaqPopup = false;
      this.showContactPopup = true;
      document.getElementById("body").style.overflowY = "hidden";
    },
    hideContactPopup(state) {
      this.showContactPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleAboutPopup(state) {
      this.showAboutPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleFaqPopup(state) {
      this.showFaqPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    showCreateTicket(state) {
      this.showFaqPopup = false;
      this.enableTicket = state;
    },
    showAppReferral(state) {
      this.showAppShare = state;
      this.showAboutPopup = false;
    },
    toggleCreateTicket(state) {
      this.enableTicket = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleAppReferal(state) {
      this.showAppShare = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    branchDeeplinks() {
      if (this.showDeepHelp) {
        this.showFaq();
      } else if (this.showDeepAbout) {
        this.showAbout();
      }
    },
  },
  components: {
    About: () =>
      import(/* webpackChunkName: "about" */ "@/components/Popups/AboutUs.vue"),
    Faq: () =>
      import(
        /* webpackChunkName: "Faq" */ "@/components/Popups/Help/FAQ/Faq.vue"
      ),
    Contact: () =>
      import(
        /* webpackChunkName: "contact" */ "@/components/Popups/Help/ContactSupport/ContactSupport.vue"
      ),
    createTicket: () =>
      import(
        /* webpackChunkName: "createTicket" */ "@/components/Popups/ProfilePopups/createTicket.vue"
      ),
    AppReferal: () =>
      import(
        /* webpackChunkName: "appReferal" */ "@/components/Popups/AppReferal.vue"
      ),
  },
  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./noorplay_Footer.scss";

:root {
  --social-login-hover-color: "red";
}

.icon a {
  display: flex;
  background: $clr-socialicons-bg;
  border: 1px solid $clr-socialicons-border;
  border-radius: 20px;
  padding: 6px;
}
.social_icons {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.footerMenu ul li a {
  color: $clr-footer-menu;
  color: hsla(0, 0%, 100%, 0.7);
  font-family: $font-regular;
  font-size: 1.1rem;
  line-height: 1.5em;
  font-weight: 400;
  cursor: pointer;
}
.footerMenu ul li a:hover,
footerMenu ul li a:active {
  color: $clr-footer-menu-hover;
}
.footerMenu ul li {
  margin: 0;
  border: 0;
  outline: 0;
  text-align: left;
  list-style: none;
  display: inline-block;
  padding: 0px 7px;
  position: relative;
}
.footerMenu ul li:after {
  content: "";
  width: 1px;
  height: 13px;
  background: $clr-footer-menu-content-separate-line;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
}
.footerMenu {
  margin: 0px -11px 0px 0px;
}

.globalDiv,
.noSelect {
  color: hsla(0, 0%, 100%, 0.7);
}
.globalDiv,
.noSelect {
  color: hsla(0, 0%, 100%, 0.7);
  font-family: $font-regular;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 400;
  cursor: pointer;
}
.footerMenu ul li:last-child:after {
  display: none;
}
.icon-paper {
  font-size: 18px;
  align-items: left;
  color: $clr-connect-txt;
  padding: 8px;
}

.icon {
  bottom: 70px;
}
.copyRightTxt {
  color: $clr-footer-cpyrhtext;
  margin: 32px 16px;
  font-size: 0.9rem;
  line-height: 1.5em;
  font-weight: 400;
  margin-left: 3px;
  letter-spacing: 0px;
  margin-left: 25px;
}
.noorplay_footer {
  background-color: $clr-footer-bg;
  padding: 0px 0px 0px 0px;
  .footer {
    .logo_container {
      .flex_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
      }
      .image_item {
        height: 28px;
      }
      .icon_list {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 5px;
        padding: 1.5px;
        li {
          text-align: center;
          img {
            height: 20px;
            width: 20px;
            &:hover {
              opacity: 0.8;
            }
          }
          &:nth-child(even) {
            padding: 0.5rem 0.8rem;
          }
        }
      }
      .nohover-text {
        opacity: 0.6;
        color: #ffffff;
        font-family: $font-regular;
        font-size: 0.7rem;
        font-weight: 400;
        letter-spacing: 0;
        margin-bottom: 1rem;
      }
    }
    .align_flex {
      display: flex;
      justify-content: space-between;
      width: 38%;
      .company_container {
        display: flex;
        .company_text {
          color: #ffffff;
          font-family: $font-regular;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 19px;
        }
        .company_text_container {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          gap: 30px;
          white-space: nowrap;
          overflow: hidden;
          .text {
            opacity: 0.5;
            color: #ffffff;
            font-family: $font-regular;
            font-size: 0.9rem !important;
            font-weight: 400 !important;
            letter-spacing: 0;
            line-height: 1.5rem;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .contact_us_container {
        .contact_text {
          color: #ffffff;
          font-family: $font-regular;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 19px;
        }
        .contact_text_container {
          margin-top: 10px;
          .contact_secondary_text {
            opacity: 0.5;
            color: #ffffff;
            font-family: $font-regular;
            font-size: 0.9rem !important;
            font-weight: 400 !important;
            letter-spacing: 0;
            line-height: 1.5rem;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    .download_our_app {
      display: flex;
      align-items: center;
      .download_text {
        color: $clr-dwnlod-txt;
        font-family: $font-regular;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
        padding: 0 7px;
        margin-left: 17px;
      }
      .app_links_container {
        display: flex;
        flex-wrap: wrap;
        .app-links {
          img {
            width: 110px;
            height: 50px;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      .Huawei_Gallery_Icon {
        padding: 8px 8px;
        img {
          width: 105px;
          height: 32px;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .bordered-div {
    margin-top: 0px !important;
  }
  .noorplay_footer .footer .logo_container .icon_list {
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .social_icons {
    display: block;
    text-align: center;
    position: relative;
    top: 86px;
  }
  .download_text {
    display: none;
  }
  .noorplay_footer .footer .logo_container .flex_container {
    display: block;
  }
  .noorplay_footer .footer .download_our_app {
    display: flex;
    text-align: center;
  }
  .noSelect {
    // display: none;
    user-select: none;
  }
  .icon-paper {
    display: inline-block;
    font-size: 0.9rem;
  }
  .noorplay_footer .footer .logo_container .icon_list {
    display: flex;
    align-items: center;
    top: 5px;
    padding: 1.5px;
  }
  .footerMenu ul {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .footerMenu ul li {
    padding: 1px 2px;
  }
  .footerMenu ul li:after {
    width: 0.3px;
    height: 13px;
  }
  .footerMenu {
    margin: 16px 8px 0;
    text-align: center;
  }
  .footerMenu ul li a {
    font-size: 0.6rem;
  }
  .globalDiv,
  .noSelect {
    font-size: 0.7rem;
  }
  .app_links_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .copyRightTxt {
    text-align: center;
  }
  .footerMenu {
    position: relative;
    bottom: 75px;
  }
  .copyRightTxt {
    font-size: 10px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .bordered-div {
    margin-top: 0px !important;
  }
  .noorplay_footer .footer .logo_container .icon_list {
    // display: block;
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .social_icons {
    display: block;
    text-align: center;
    position: relative;
    top: 105px;
  }
  .download_text {
    display: none;
  }
  .noorplay_footer .footer .logo_container .flex_container {
    display: block;
  }
  .noorplay_footer .footer .download_our_app {
    display: flex;
    text-align: center;
  }
  .noSelect {
    // display: none;
    user-select: none;
  }
  .icon-paper {
    display: inline-block;
    font-size: 1rem;
  }
  .noorplay_footer .footer .logo_container .icon_list {
    display: flex;
    align-items: center;
    top: 5px;
    padding: 1.5px;
  }
  .footerMenu ul {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .footerMenu ul li {
    padding: 1px 2px;
  }
  .footerMenu ul li:after {
    width: 0.3px;
    height: 13px;
  }
  .footerMenu {
    margin: 16px 8px 0;
    text-align: center;
  }
  .footerMenu ul li a {
    font-size: 14px;
  }
  .globalDiv,
  .noSelect {
    font-size: 0.9rem;
  }
  .app_links_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .copyRightTxt {
    text-align: center;
  }
  .footerMenu {
    position: relative;
    bottom: 70px;
  }
  .copyRightTxt {
    font-size: 14px;
    margin: 50px 16px;
  }
}
@media (min-width: 768px) and (max-width: 1100px) {
  .noorplay_footer .footer .logo_container .icon_list {
    // display: block;
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .social_icons {
    display: block;
    text-align: center;
    position: relative;
    top: 105px;
  }
  .download_text {
    display: none;
  }
  .noorplay_footer .footer .logo_container .flex_container {
    display: block;
  }
  .noorplay_footer .footer .download_our_app {
    display: flex;
    text-align: center;
  }
  .noSelect {
    // display: none;
    user-select: none;
  }
  .icon-paper {
    display: inline-block;
    font-size: 1rem;
  }
  .noorplay_footer .footer .logo_container .icon_list {
    display: flex;
    align-items: center;
    top: 5px;
    padding: 1.5px;
  }
  .footerMenu ul {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .footerMenu ul li {
    padding: 1px 2px;
  }
  .footerMenu ul li:after {
    width: 0.3px;
    height: 13px;
  }
  .footerMenu {
    margin: 16px 8px 0;
    text-align: center;
  }
  .footerMenu ul li a {
    font-size: 14px;
  }
  .globalDiv,
  .noSelect {
    font-size: 0.9rem;
  }
  .app_links_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .copyRightTxt {
    text-align: center;
  }
  .footerMenu {
    position: relative;
    bottom: 70px;
  }
  .copyRightTxt {
    font-size: 14px;
    margin: 50px 16px;
  }
}

.bordered-div {
  width: 90px;
  height: 35px;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 5px;
  margin-left: 6px;
  margin-right: 6px;
}

.contained {
  max-width: 100% !important;
  max-height: 100% !important;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px !important;
}

.icon a:hover {
  background-color: var(--social-login-hover-color);
}
</style>
